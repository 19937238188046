<template>
	<main-tabs :links="links">
		<keep-alive>
			<router-view></router-view>
		</keep-alive>
	</main-tabs>
</template>

<script>
	export default {
		data() {
			return {
				links: [{
					to: {
						path: '/campaign-ltv/campaign'
					},
					label: 'Campaign'
				}, {
					to: {
						path: '/campaign-ltv/ltv'
					},
					label: 'LTV'
				}]
			}
		}
	}
</script>
